import React from 'react'

import SEO from '../components/seo'
import Header from '../components/header'

import GalleryContainer from '../components/gallery/GalleryContainer'

const GalleryPage = () => {
  return (
    <>
      <SEO path="/gallery" title="Gallery" />
      <Header isGalleryPage={true} />
      <GalleryContainer />
    </>
  )
}

export default GalleryPage
