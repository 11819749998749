import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import axios from "axios"
import Fade from "react-reveal/Fade"
import Rellax from "rellax"

import PlayIcon from "../../assets/images/play-icon.svg"

import GalleryModal from "./GalleryModal"

import "./GalleryContainer.scss"

class GalleryContainer extends Component {

  state = {
    showModal: false,
    activeItem: {},
    items: [],
  }

  componentDidMount() {
    const p = this.getVideoData()
    p.then((v) => {
      const rellax = new Rellax('.rellax', {
        center: true,
        wrapper: null,
        vertical: true,
        horizontal: false
      })
    })
  }

  getVideoData = async () => {
    const { gallery } = this.props.data.wpPage.acf
    let { items } = this.state

    for (let i = 0; i < gallery.length; i++) {
      let obj = undefined;

      if (gallery[i].video) {
        let response = await axios.get("https://noembed.com/embed?url=" + gallery[i].video)
        obj = response.data
      }

      items.push(obj)
    }

    this.setState({ items })
  }

  render() {
    let { showModal, activeItem, items } = this.state

    const openModal = (item) => () => {
      document.querySelector("body").classList.add("model-open")
      this.setState({ showModal: true })

      axios
        .get("https://noembed.com/embed?url=" + item.video)
        .then((response) => {
          item.embed = response.data.html
          this.setState({ activeItem: item })
        })
    }

    const closeModal = () => {
      document.querySelector("body").classList.remove("model-open")
      this.setState({ showModal: false, activeItem: {} });
    }



    return (
      <>
        <GalleryModal
          showModal={showModal}
          toggleModal={closeModal}
          data={activeItem}
        />
        <section className="gallery-page">
          <div className="gallery-page__container">
            {this.props.data.wpPage.acf.gallery.map((el, i) => (
              <div
                key={i}
                className="gallery-page__container__content"
                onClick={openModal(el)}
              >
                <Fade delay={i * 50} bottom distance="40px">
                  {el.video && items[i] ? (
                    <>
                      <div className='image-wrap'>
                        <img className="rellax" src={items[i].thumbnail_url}  data-rellax-speed="-0.7" alt={items[i].title} />
                      </div>
                      <div className="play-icon">
                        <img src={PlayIcon} alt="Play Icon" />
                      </div>
                    </>
                  ) :
                    <Img
                      fluid={el.poster.localFile.childImageSharp.fluid}
                      alt={el.caption || el.poster.altText}
                    />
                  }
                  <span>{el.caption}&nbsp;</span>
                </Fade>
              </div>
            ))}
          </div>
        </section>
      </>
    )
  }
}

export default function GalleryContainerExport(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          wpPage(databaseId: { eq: 354 }) {
            seo {
              title
              metaDesc
            }
            acf {
              gallery {
                video
                caption
                poster {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <GalleryContainer data={data} {...props} />}
    />
  )
}
